import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import Layout from 'components/Layout';
import SmallPrint from 'components/SmallPrint';
import Headline from 'components/Headline';
import Section from 'components/Section';
import SectionsWrapper from 'components/SectionsWrapper';
import { useLayoutContext } from 'context/LayoutContext';
import externalLinks from 'utils/externalLinks';
import SEO from 'components/SEO';

const SecurityWrapper = styled(SectionsWrapper)`
  margin-top: 6.5625rem;

  ${ifProp('$showAlert', css`
    margin-top: 9.5625rem;
  `)}

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    padding-bottom: 2rem;
  }

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 10.0625rem;

    ${ifProp('$showAlert', css`
      margin-top: 13.0625rem;
    `)}
  }
`;

const HeadlineSection = styled(Section)`
  padding-bottom: 2rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    padding-bottom: 2.5rem;
  }

  ${Headline} {
    text-align: left;
    padding-bottom: 0.625rem;
  }

  h4 {
    font-family: ${prop('theme.fonts.default')};
    font-size: 1.125rem;
    font-weight: 700;

    @media (max-width: ${prop('theme.breakpoints.md')}) {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
`;

const SecuritySection = styled(Section)`
  padding-bottom: 2rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    padding-bottom: 3.125rem;
  }

  &&& {
    a {
      color: ${prop('theme.colors.primaryRed')};
      font-weight: 700;
      text-decoration: none;

      &:hover {
        color: ${prop('theme.colors.gray')};
        text-decoration: none;
      }
    }
  }
`;

const SectionHeader = styled.div`
  font-family: ${prop('theme.fonts.default')};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.01em;
`;

const SecurityPage = () => {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  return (
    <Layout>
      <SEO title='Security' canonicalHref='https://tastytrade.com/security' />
      <SecurityWrapper noGutters $showAlert={showAlert}>
        <HeadlineSection withPadding>
          <Headline>
            Security
          </Headline>
          <hr />
          <h4>Good security is hard work. We&apos;re working hard to protect you.</h4>
        </HeadlineSection>
        <SecuritySection withPadding>
          <SectionHeader>
            Basic Foundations
          </SectionHeader>
          <SmallPrint bodyText>
            tastytrade handles the Security of your Account and your Personal
            Information very seriously. We consider Security from top to bottom
            within our organization, and design and deploy a myriad of Defensive
            System Configurations, Security Devices, Sentinels and Real-time
            Monitoring systems to protect access and privacy surrounding your
            Account.
          </SmallPrint>
          <SmallPrint bodyText>
            As a rule, we encrypt as much of the data and interactions with you
            as possible, which means encrypting the data at rest, in use and in
            transit. We employ industry recognized and adopted high standards in
            encryption protocols to protect the data that flows between you and
            our systems (TLS v1.2 256-bit).  Your connection to our servers are
            signed with SSL Certificates issued from well-known and trusted
            authorities to ensure that you can trust that you are communicating
            with us and not some imposter.
          </SmallPrint>
          <SmallPrint bodyText>
            To protect your identity and access to your Account we hash your
            passwords and other important security credentials to make it very
            difficult to determine what they are, even if someone has direct
            access to the raw information.
          </SmallPrint>

        </SecuritySection>
        <SecuritySection withPadding>
          <SectionHeader>
            Ever Vigilant
          </SectionHeader>
          <SmallPrint bodyText>
            tastytrade regularly reviews, assesses, audits and fortifies our
            systems and code. We engage professional security consultants to
            identify and assess security vulnerabilities, proactively test our
            defensive measures, and independently review our systems.
          </SmallPrint>
          <SmallPrint bodyText>
            We monitor employee access to data, run background checks, segregate
            internal business units and their access to information as necessary.
            We control access to our Networks with very stringent policies and
            controls.
          </SmallPrint>
        </SecuritySection>
        <SecuritySection withPadding>
          <SectionHeader>
            Good Security Requires Your Help, Too
          </SectionHeader>
          <SmallPrint bodyText>
            We do our part to protect you and your Account, but you can help
            protect yourself with the following practices:
          </SmallPrint>

          <SmallPrint bodyText>
            Choose a strong password. Part of a good strategy for security is to
            choose Strong Passwords, and more importantly ones that are not shared
            with other accounts at other organizations. This way, a compromise
            at another company will not inadvertently give away your credentials
            here at tastytrade. For a list of bad passwords, take a look at this
            list:
          </SmallPrint>
          <SmallPrint bodyText>
            <a
              href={externalLinks.worstPasswords}
              target="_blank"
              rel="noopener noreferrer"
            >
              1000 Worst Passwords
            </a>
          </SmallPrint>
          <SmallPrint bodyText>
            <em>Monitor your account activity.</em> Make sure you are aware of
            any changes or activity in your account, and if you see anything
            which you can’t explain, please notify us immediately. We will
            normally try to notify you using emails, sms messages or push
            notifications for certain changes in your account, but there may be
            some instances where you may notice something wrong before receiving
            a notification from us.
          </SmallPrint>
        </SecuritySection>
      </SecurityWrapper>
    </Layout>
  )
}

export default SecurityPage;
